import React, { useState } from "react"
import { Helmet } from "react-helmet"
import GatsbyImage from "gatsby-image"
import css from "@emotion/css"
import { graphql } from "gatsby"
import { Collapse } from "reactstrap"
import Arrow from "../images/arrow.png"

export default function Index({ data }) {
  const [faqOpened, setFaqOpened] = useState(null)
  
  const {
    contentfulHomePage: {
      faqTitle,
      faq,
      applyNowImage,
    }
  } = data

  return (
    <>
      <Helmet>
        <title>Jobs @ Shpock</title>
      </Helmet>
      <div className="container">
        <GatsbyImage fluid={applyNowImage.fluid} />

        <p className="text-center" style={{marginTop: "1.5rem"}}>
          <a
            className="btn btn-primary text-white"
            id="view-all-jobs-link"
            href="https://shpock.jobs.personio.com"
            style={{ padding: "16px 42px", fontSize: "24px", borderRadius: "36px", marginBottom: "32px" }}
          >
            <span>View all jobs</span>
          </a>
        </p>

        <h2 className="text-center my-4 h1">{faqTitle}</h2>
        <div id="FQA" style={{marginBottom: "48px"}}>
          {faq.map((qa, i) => (
            <div
              css={css`
                border-bottom: 1px solid #e2e2e2;
              `}
            >
              <h2
                className={faqOpened === i ? "open" : ""}
                css={css`
                  margin: 0;
                  font-size: 1.3rem;
                  padding: 1rem 15px;
                  cursor: pointer;
                  position: relative;
                  clear: both;
                  /* @md */
                  @media (min-width: 768px) {
                    padding: 1rem 2rem 1rem 0;
                  }
                `}
                onClick={(e) =>
                  faqOpened === i ? setFaqOpened(null) : setFaqOpened(i)
                }
              >
                <img
                  alt="arrow"
                  src={Arrow}
                  css={css`
                    float: right;
                    margin-left: 1em;
                    height: 26px;
                    transition: transform 300ms;
                    .open & {
                      transform: rotate(90deg);
                    }
                  `}
                />
                {qa.question}
              </h2>
              <Collapse isOpen={faqOpened === i}>
                <div
                  css={css`
                    font-size: 1rem;
                    color: #414141;
                    padding: 0rem 15px 0 15px;
                    /* @md */
                    @media (min-width: 768px) {
                      padding: 0rem 15px 0 0px;
                    }
                  `}
                  dangerouslySetInnerHTML={{ __html: qa.answer.md.html }}
                />
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    contentfulHomePage {
      heroTItle
      heroImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      slug
      title
      applyNowImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      faqTitle
      faq {
        id
        question
        answer {
          md: childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
